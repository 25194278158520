<i18n>
{
  "nl-BE": {
    "logout": "Afmelden",
    "login": "Log in"
  },
  "en-BE": {
    "logout": "Logout",
    "login": "Log in"
  },
  "fr-BE": {
    "logout": "Déconnexion",
    "login": "S'identifier"
  }
}
</i18n>

<template>
    <b-navbar id="navbar" type="is-white" :class="{ 'is-fixed-top': fixed }">
        <!-- this can be link to main page -->
        <template slot="brand">
            <b-navbar-item
                tag="router-link"
                :to="
                    email
                        ? {
                              name: 'request',
                              params: { action: 'start', lang: this.$i18n.locale },
                          }
                        : { name: 'login', params: { lang: this.$i18n.locale } }
                "
            >
                <img
                    src="../assets/icons/rockestate-logo-horizontal-dark.svg"
                    class="logo"
                    width="148"
                />
            </b-navbar-item>
        </template>
        <template slot="start">
            <b-navbar-item
                tag="router-link"
                :to="{
                    name: 'start',
                    params: {
                        lang: $route.params.lang,
                    },
                }"
            >
                {{ $t('explorer') }}
            </b-navbar-item>
            <b-navbar-item
                v-if="roles.includes('admin')"
                tag="router-link"
                :to="{
                    name: 'admin',
                    params: {
                        lang: $route.params.lang,
                    },
                }"
            >
                {{ $t('admin') }}
            </b-navbar-item>
        </template>
        <template slot="end">
            <b-navbar-item tag="div">
                <div class="langs buttons has-addons my-0">
                    <b-button
                        size="is-small"
                        class="my-0"
                        :type="{
                            'is-primary': this.$route.params.lang === 'nl-BE',
                        }"
                        @click.native="changeLang('nl-BE')"
                    >
                        <span>NL</span>
                    </b-button>
                    <b-button
                        size="is-small"
                        class="my-0"
                        :type="{
                            'is-primary': this.$route.params.lang === 'fr-BE',
                        }"
                        @click.native="changeLang('fr-BE')"
                    >
                        <span>FR</span>
                    </b-button>
                    <b-button
                        size="is-small"
                        class="my-0"
                        :type="{
                            'is-primary': this.$route.params.lang === 'en-BE',
                        }"
                        @click.native="changeLang('en-BE')"
                    >
                        <span>EN</span>
                    </b-button>
                </div>
                <div class="mr-4 px-1 is-size-7 user" :class="[email ? 'ml-6' : 'ml-4']">
                    {{ email }}
                </div>
                <div class="buttons">
                    <a v-if="email != null" :href="logout_url">
                        <b-button size="is-small" type="is-primary" outlined>
                            <span>{{ $t('logout') }}</span>
                        </b-button>
                    </a>
                </div>
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>
import utils from '../plugins/utils'

export default {
    name: 'Navbar',
    props: {
        email: {
            type: String,
            default: null,
        },
        roles: {
            type: Array,
            default: () => [],
        },
        fixed: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        logout_url: function () {
            return utils.urlJoin(process.env.VUE_APP_AUTH_API_URL, 'logout')
        },
    },
    watch: {
        fixed() {
            document.body.classList.toggle('has-navbar-fixed-top', this.fixed)
        },
    },
    methods: {
        changeLang(lang) {
            this.$i18n.locale = lang
            localStorage.setItem('lang', lang)
            let params = this.$route.params
            params.lang = lang
            this.$router.push({
                name: this.$route.name,
                query: this.$route.query,
                params: params,
            })
        },
    },
}
</script>

<style lang="scss" scoped>
// no need for separate file less load with scope
#navbar {
    padding: 0 15px;
    border-bottom: 1px solid #d6e2e9;
}
</style>
