<i18n>
{
  "nl-BE": {
    "enter_address": "Vul het adres hier in",
    "not_found": "Het adres staat niet in de lijst"
  },
  "en-BE": {
    "enter_address": "Enter address here",
    "not_found": "The address is not in the list"
  },
  "fr-BE": {
    "enter_address": "Entrer une adresse ici",
    "not_found": "L'adresse n'est pas dans la liste"
  }
}
</i18n>

<template>
    <b-field expanded>
        <p class="control mb-1">
            <b-button
                type="submit"
                :size="size"
                class="button is-primary"
                icon-right="magnify"
                @click="$emit('selectedItem', selected)"
            ></b-button>
        </p>
        <b-autocomplete
            :size="size"
            :placeholder="placeholder || $t('enter_address')"
            clearable
            expanded
            field="full_address"
            :data="data"
            :loading="isFetching"
            :disabled="readOnly"
            @typing="getAsyncData"
            @select="(option) => (selected = option)"
        >
            <template slot-scope="props">
                <div class="media is-flex is-align-items-center">
                    <div class="media-left">
                        <b-icon icon="map-marker" type="is-primary" :size="size"></b-icon>
                    </div>
                    <div class="media-content">
                        {{ props.option.full_address }}
                    </div>
                </div>
            </template>
        </b-autocomplete>
    </b-field>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
    props: {
        size: {
            type: String,
            default: 'is-medium',
        },
        placeholder: {
            type: String,
            default: '',
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selected: '',
            isFetching: false,
            data: [],
        }
    },
    watch: {
        selected(val) {
            this.$emit('selectedItem', val)
        },
    },
    methods: {
        getAsyncData: debounce(function (name) {
            if (!name.length) {
                this.data = [{ building_id: null, full_address: this.$t('not_found') }]
                return
            }
            this.isFetching = true
            this.$axios
                .get(`/autocomplete?term=${name}`)
                .then(({ data }) => {
                    this.data = []
                    data.results.forEach((item) => this.data.push(item))
                    this.data.push({
                        building_id: null,
                        full_address: this.$t('not_found'),
                    })
                })
                .catch((error) => {
                    this.data = [
                        { building_id: null, full_address: this.$t('not_found') },
                    ]
                    throw error
                })
                .finally(() => {
                    this.isFetching = false
                })
        }, 500),
    },
}
</script>

<style lang="scss" scoped></style>
